.App {
  height: 100vh;
  background-color: #0d160d;
  background-image: url("../assets/larva-crab-background-large.png");
  min-height:100%;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: auto;
  overflow-y: scroll;
  text-align: center;
  -moz-background-size: cover;
}

.appbar {
  height: 56px;
  background-color: white;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appbar-button {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.appbar-button:hover {
  cursor: pointer;
}

.appbar-button:not(:last-of-type) {
  margin-right: 28px;
}

.appbar-logo {
  width: 40px;
  height: 40px;
}

.appbar-logo:hover {
  border: 2px solid #35aee2;
  border-radius: 100%;
  padding: 2px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 600px;
  margin: 24px auto;
  padding: 24px;
  background-color: #0d16168a;
  border-radius: 4px;
}

.header {
  margin: 0;
  font-size: 48px;
  font-weight: bold;
}

.sub-heading {
  margin: 0;
  font-size: 26px;
  font-weight: bold;
}

.sub-text {
  font-size: 20px;
  color: white;
}

.wallet-address-text {
  font-size: 16px;
  color: #fff;
  margin-top: 0;
  margin-bottom: 24px;
  overflow-wrap: break-word;
}

.success-message {
  color: #fff;
  margin-bottom: 24px;
}

.etherscan-link {
  color: #60c657;
}

.opensea-link {
  color: #35aee2;
}

.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-button {
  align-self: center;
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.connect-wallet-button {
  width: 256px;
}

.mint-button {
  width: 184px;
}

.connect-wallet-button,
.mint-button,
.subtract-button,
.add-button {
  background: -webkit-linear-gradient(left, #60c657, #35aee2);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mint-button:disabled,
.mint-button[disabled],
.subtract-button:disabled,
.subtract-button[disabled],
.add-button:disabled,
.add-button[disabled] {
  cursor: default;
  opacity: 0.5;
}

.subtract-button {
  margin-right: 4px;
}

.add-button {
  margin-left: 4px;
}

.larva-crabs-gif {
  width: 350px;
  height: 350px;
  align-self: center;
  margin-bottom: 24px;
  padding: 8px;
  border: 1px solid white;
}

.opensea-button {
  background-color: rgb(32, 129, 226);
  margin-top: 16px;
  margin-bottom: 24px;
}

.mint-price {
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin-top: 16px;
}

.mint-count {
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin-top: 16px;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.dev-twitter-logo {
  width: 28px;
  height: 28px;
  margin-right: 2px;
}

.footer-text {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.testnet-message-container {
  width: 100vw;
  height: 56px;
  text-align: center;
  background-color: #0d160d;
  z-index: 10000;
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testnet-message-dismiss-button {
  background-color: #c40d0d;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  padding: 6px 14px;
  margin-left: 8px;
}

.testnet-message-dismiss-button:hover {
  background-color: #b10a0a;
  box-shadow: 4px 4px 4px #0d16168a;
  cursor: pointer;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 649px) {
  .container {
    margin: 0;
    border-radius: 0px;
  }
}
